const tabs = [
  {
    name: "Resume",
    url: "https://cdn.sanity.io/files/465yo57o/production/3b16c1a2e25a9794ee9841e0355a13d47e125872.pdf",
  },
  {
    name: "Projects",
    url: "/projects",
  },
  {
    name: "Photography",
    url: "/photography",
  },
  {
    name: "Journal",
    url: "/journal",
  },
];

export default tabs;
